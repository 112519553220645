import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { QuizModel } from "@/models";
import { StCommon } from "../Common";

export class QuizForm {
    sqid = '';
    name = '';
    student = '';
    city = '';
    school = '';
    class = '';
    unit = '';
    level = '';
    grade = '';
    question = {};
    accuracy = 0;
    completeDate = '';
    active = '';
}

type response = { [key: string]: unknown };

@Component<ApStudentTestResultInfoController>({
    components: {
        STHeader,STFooter
    }
})

export default class ApStudentTestResultInfoController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private quizForm = new QuizForm();
    private token = window.localStorage.getItem('studentToken') as string;
    private stid = window.localStorage.getItem('student_stid') as string;

    public async created() {
        const item: { [key: string]: string } = {
            sqid: this.$route.query.sqid as string,
            stid: this.stid,
            token: this.token,
        }
        const editData = await QuizModel.stGetResultMemData(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        StCommon.checkToken(data.ERR_CODE as number);

        if (Object.keys(data.info as object).length == 0) {
            this.$router.push('/student/quiz/result');
            return;
        }
        const item = data.info as {
            sqid: string;
            name: string;
            student: string;
            city: string;
            school: string;
            class: string;
            grade: string;
            unit: string;
            level: string;
            completeDate: string;
            question: { [key: string]: any };
            accuracy: number;
            active: string;
        };
        this.quizForm.sqid = item.sqid;
        this.quizForm.name = item.name;
        this.quizForm.student = item.student;
        this.quizForm.city = item.city;
        this.quizForm.school = item.school;
        this.quizForm.class = item.class;
        this.quizForm.grade = item.grade;
        this.quizForm.unit = item.unit;
        this.quizForm.level = item.level;
        this.quizForm.completeDate = item.completeDate;
        this.quizForm.question = item.question;
        this.quizForm.accuracy = item.accuracy;
        this.quizForm.active = item.active;
    }
}